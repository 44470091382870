import { renderGalleryRelatedItems } from './galleryRelatedItems.js';

const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const galleryVehicleFields = ['gallery_make', 'gallery_model', 'gallery_year'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern', 'wheel_offset'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, ...galleryVehicleFields];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';
const isCategoryPage = !!window.document.querySelector('.page--category');
const isVehicleLandingPage = !!window.document.querySelector('#cm_landing-page');
const isVehicleGalleryPLP = !!window.document.querySelector('#cm_fitment-gallery');
const isVehicleGalleryPDP = window.location.pathname.startsWith('/vehicle-gallery/') && !isVehicleGalleryPLP;

// because Vehicle field on landings is sending from the Client, we need to hide it manually from chips
if (isVehicleLandingPage) {
  ignoreFields.push('Vehicle');
}

// get the number of tires we have to add to the cart after selecting the wheels
window.Convermax.getTiresQtyForPackage = () =>
  window.document
    .querySelector('#cm_MountedPackageDialogButton .cm_button')
    .getAttribute('mounted-package-quantity');

window.Convermax.redirectToTiresCategory = (productId) => async (e) => {
  const { href, pathname } = window.location;

  const redirectPath = href.includes('WheelProductID')
    ? '/cart.php'
    : pathname === '/mounted-packages/'
      ? `/tires/?WheelProductID/${productId}/${window.Convermax.getVehicleSearchParams()}`
      : null;

  if (redirectPath) {
    e.preventDefault();
    e.stopPropagation();

    await window.Convermax.addToCartProduct(productId);

    window.location.replace(redirectPath);
  }
};

window.Convermax.addToCartProduct = async (productId, quantity = 4) => {
  const currentCart = await getCart();

  // on bigcommerce we can't just add a product to the cart,
  // because if the cart is not empty we must first get the cart object and its id,
  // if the cart was empty we must first create a new cart,
  // then get its id and add the product to the new cart.
  if (currentCart.length === 0) {
    await createCart(productId, quantity);
  } else {
    await addCartItem(currentCart[0].id, productId, quantity);
  }

  const cartQuantity = window.document.querySelector('.navUser-item--cart .cart-quantity');
  if (cartQuantity) {
    cartQuantity.innerText = parseInt(cartQuantity.innerText) + quantity;
  }
};

window.Convermax.openMountedPackageDialog = async (productId, quantity, category) => {
  await window.Convermax.addToCartProduct(productId, quantity);

  if (category.includes('Wheel')) {
    const dialogContainer = window.document.querySelector('#cm_MountedPackageDialog');
    const dialogButton = window.document.querySelector('#cm_MountedPackageDialogButton .cm_button');

    if (!dialogButton || !dialogContainer) {
      return;
    }

    dialogContainer.addEventListener('click', () => {
      dialogContainer.style.display = 'none';
    });

    // add attribute with the quantity of wheels which added in the cart.
    dialogButton.setAttribute('mounted-package-quantity', quantity);

    dialogButton.click();
  }
};

async function createCart(id, quantity) {
  return fetch(`/api/storefront/carts`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItems: [
        {
          quantity,
          productId: id,
        },
      ],
    }),
  }).then((response) => response.json());
}

async function getCart() {
  return fetch('/api/storefront/carts', {
    method: 'GET',
    credentials: 'same-origin',
  }).then((response) => response.json());
}

async function addCartItem(cartId, productId, quantity) {
  const route = `/api/storefront/carts/${cartId}/items`;

  return fetch(route, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItems: [
        {
          quantity,
          productId,
        },
      ],
    }),
  });
}

function getLocalPreselection(pageType, defaults) {
  const defaultPreselection = [{ field: 'is_gallery_item', term: isVehicleGalleryPLP ? 'True' : 'False' }];

  if (isVehicleLandingPage) {
    return [...defaultPreselection, { field: 'Vehicle', term: window.location.pathname }];
  }

  return defaultPreselection;
}

function getSelection(itemData, fields) {
  return fields.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function responseHandler(response) {
  const { Facets, TotalHits } = response;

  const isProductPage = !!window.document.querySelector('.container .productView');
  const isRelatedResponse = !!Facets.find((f) => f.FieldName === 'WheelProductID');

  if (isProductPage && isRelatedResponse) {
    if (window.Convermax.isVehicleSelected() && TotalHits > 0) {
      window.document.querySelector('#cm_MountedPackageDialog').style.display = 'flex';
    } else {
      window.document.body.classList.remove('cm_no-scroll');

      return window.location.assign('/cart.php');
    }
  }

  return response;
}

const getItemId = () => {
  if (isVehicleGalleryPDP) {
    // "/vehicle-gallery/1/1990-Nissan-Skyline-Falken-KOKORO-MonoC-Coilovers" -> "1"
    const [, id] = window.location.pathname.split('/').filter(Boolean);

    if (id) {
      return `90000${id}`;
    }
  }

  return window.document.querySelector('input[name="product_id"]').value;
};

const AfterInit = () => {
  if (isVehicleGalleryPDP) {
    renderGalleryRelatedItems(getItemId());
  }
};

export default {
  platform: 'bigcommerce',
  AfterInit,
  getLocalPreselection,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 30,
  },
  page: {
    getPageType: (defaults) =>
      isVehicleGalleryPDP
        ? 'product'
        : window.location.pathname === categorySelectionPageUrl ||
            window.location.pathname === brandSelectionPageUrl
          ? 'parts'
          : defaults.getter(),
  },
  pagination: {
    edgeRange: 0,
    centralRange: 5,
    disableDelimeters: true,
  },
  product: {
    getItemId,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    forceVehicleSelection: !isVehicleGalleryPLP,
    isAutoVehicleSelectionDisabled: isCategoryPage || isVehicleLandingPage,
    isVehicleSelectionIsolated: true,
    categorySelectionPageRedirect: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.page--category .main .container',
      template: 'SearchPage',
      visibleIf: () =>
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl &&
        !window.Convermax.config?.disableCategoryIntegration,
    },
    {
      name: 'VehicleLandingPage',
      type: 'SearchPage',
      location: '#cm_landing-page',
      template: 'SearchPage',
    },
    {
      name: 'VehicleGalleryPage',
      type: 'SearchPage',
      location: '#cm_fitment-gallery',
      template: 'vehicleGallery/SearchPage',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetBarGalleryVehicle',
      type: 'FacetBar',
      fields: galleryVehicleFields,
      visibleIf: () => isVehicleGalleryPLP,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: {
        selector: '#quickSearch',
        class: 'cm_mobile-hide',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.navUser-item--mobile-search',
        wrapper: 'li',
        class: 'navUser-item navUser-item--mobile-search',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'HomeWheelPanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_wheel-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...wheelsFields, 'brand_name'],
    },
    {
      name: 'HomeTirePanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...tiresFields, 'brand_name'],
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
      visibleIf: () => !isVehicleGalleryPLP,
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser-item--account',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: { insertAfter: 'body .productView .productView-specs' },
      template: 'fitmentSearch/verifyFitment',
      visibleIf: () => !window.Convermax?.isPageWithoutVerify,
    },
    {
      name: 'MountedPackageItems',
      type: 'RelatedItems',
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
    {
      name: 'WheelTireVehicleInfo',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.page--category .main .container',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '.page-content-subcategories',
      template: 'facetTiles/alphabeticalContainer',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
      view: 'grid',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: isVehicleLandingPage ? ['Vehicle'] : [],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'MountedPackageDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/mountedPackageDialog',
    },
    {
      name: 'MountedPackageDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'MountedPackageDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
      isAlwaysColumnLayout: true,
      doNotRedirectOnVehicleSelect: isCategoryPage || isVehicleLandingPage,
    },
  ],
};

export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  SEARCH_BOX_CLASS: 'form',
  SEARCH_BOX_FORM_CLASS: 'form-input quickSearchWrap',
  SEARCH_BOX_PLACEHOLDER: 'What are you looking for?',

  SELECT_YOUR_VEHICLE_BLOCK: 'Select Your Vehicle',
  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',

  GARAGE_SIZE: '<span class="garage-size" key="garage-size">({{size}})</span>',
  ADD_VEHICLE_BUTTON_CLASS: 'button--small',

  VEHICLE_INFO_TITLE: 'Vehicle Sizes',
};
